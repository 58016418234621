import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import { Language, languages } from "./types";

export const makeFetch = wretch()
  .headers({
    "User-Agent": "HGV/MetaSearchLandingPage/1.0",
  })
  .addon(QueryStringAddon);

export const loadScript = (src: string) => {
  const script = document.createElement("script");
  script.src = src;
  document.body.appendChild(script);

  return new Promise<Event>((resolve, reject) => {
    const onLoad = (event: Event) => {
      script.removeEventListener("load", onLoad);
      resolve(event);
    };

    script.addEventListener("load", onLoad);

    const onError = (event: ErrorEvent) => {
      script.removeEventListener("error", onError);
      reject(event);
    };

    script.addEventListener("error", onError);
  });
};

export const checkIsLanguage = (value: string | undefined): value is Language =>
  languages.includes(<Language>value);

export const getHomeURLs = (
  propertyId: number
): { [l in Language]: string } => ({
  de: `/${propertyId}?language=de`,
  it: `/${propertyId}?language=it`,
  en: `/${propertyId}?language=en`,
});

export const getMSSImageURL = ({
  url,
  width,
  aspectRatio,
}: {
  url: string;
  width: number;
  aspectRatio: number;
}) => {
  const u = new URL(url);
  u.searchParams.set("w", String(width));
  u.searchParams.set("h", String(getHeight(width, aspectRatio)));
  u.searchParams.set("m", "4");
  return u.toString();
};

export const getMSSImageSrcSet = ({
  baseImageURL,
  widths,
  aspectRatio,
}: {
  baseImageURL: string;
  widths: number[];
  aspectRatio: number;
}) =>
  widths
    .map(
      (width) =>
        `${getMSSImageURL({ url: baseImageURL, width, aspectRatio })} ${width}w`
    )
    .join(",");

export const getHeight = (width: number, aspectRatio: number) =>
  Math.round(width / aspectRatio);

export const getLanguage = (url: URL): Language => {
  const langFromQuery = url.searchParams.get("language");
  if (!langFromQuery) return "de";
  return checkIsLanguage(langFromQuery) ? langFromQuery : "en";
};
